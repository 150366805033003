import React, { Component } from "react";
import { auth } from "../../Firebase";

import Login from "./Login";
import TicketList from "./TicketList";

export default class TicketListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      let currentUser = null;
      if (user && user.emailVerified) {
        currentUser = user;
      }
      this.setState({ user: currentUser });
    });
  }

  render() {
    const user = this.state.user;
    return user ? <TicketList user={user} /> : <Login />;
  }
}
