import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { firestore, functions } from "../../Firebase";
import { Link } from "react-router-dom";
import {
  formatFirebaseTimestamp,
  formatLatestTimestamp,
} from "../../../utils/dateUtils";

export default function TicketList(props) {
  const [ticketType, setTicketType] = useState(true);
  const [ticketData, setTicketData] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = props.user;

  useEffect(() => {
    setLoading(true);
    const getUserTickets = functions.httpsCallable("getUserSupportTickets");
    getUserTickets({
      userId: user.uid,
      isOpen: ticketType,
    })
      .then((result) => {
        console.log("Support tickets:", result.data);
        setTicketData(result.data.tickets);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching support tickets:", error);
        setLoading(false);
        setTicketData([]);
      });
  }, [ticketType, user.uid]);

  return (
    <div className="home">
      <Helmet>
        <title>Trippie Air - Support</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="tickets-header-text">
            <h1 className="medium-heading">Support Tickets List</h1>
            <Link
              to="/support/create-ticket"
              className="standard-button invert-btn-color"
            >
              New Ticket
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gradient">
        <div className="container-middle-content">
          <div className="switch-buttons-container">
            <button
              className={
                ticketType
                  ? "switch-buttons terms-buttons active fw-600"
                  : "switch-buttons terms-buttons fw-600"
              }
              onClick={() => setTicketType(true)}
            >
              Open Tickets
            </button>
            <button
              className={
                !ticketType
                  ? "switch-buttons terms-buttons active fw-600"
                  : "switch-buttons terms-buttons fw-600"
              }
              onClick={() => setTicketType(false)}
            >
              Closed Tickets
            </button>
          </div>
        </div>
      </div>
      <div className="container-middle-content">
        <div className="support-ticket-list">
          <h1 className="small-heading">
            List of all your {ticketType ? "open" : "closed"} tickets
          </h1>
          <div className="ticket-box-title fw-600">
            <div className="ticket-id w20">Ticket Number</div>
            <div className="ticket-reason w40">Reason</div>
            <div className="ticket-date w20">Date of Submission</div>
            <div className="ticket-date w20">Last Message</div>
          </div>
          {loading ? (
            <div className="ticket-link">
              <div className="ticket-box">
                <div className="ticket-reason w100">Loading tickets...</div>
              </div>
            </div>
          ) : ticketData.length > 0 ? (
            ticketData.map((ticket, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    className="ticket-link"
                    to={`/support/ticket/${ticket.id}`}
                  >
                    <div className="ticket-box">
                      <div className="ticket-id w20">
                        {ticket.ticketNo || `#${ticket.id.substring(0, 8)}`}
                      </div>
                      <div className="ticket-reason w40">{ticket.reason}</div>
                      <div className="ticket-date w20">
                        {formatFirebaseTimestamp(ticket.createdAt)}
                      </div>
                      <div className="ticket-date w20">
                        {ticket.responses && ticket.responses.length > 0
                          ? formatLatestTimestamp(ticket.responses, "createdAt")
                          : "No messages"}
                      </div>
                    </div>
                  </Link>
                </React.Fragment>
              );
            })
          ) : (
            <div className="ticket-link">
              <div className="ticket-box">
                <div className="ticket-reason w100">
                  You do not have any {ticketType ? "open" : "closed"} tickets
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
