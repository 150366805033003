import React from 'react';

import TravellerStepOne from '../../../img/how-to-img/world-tour.png';
import TravellerStepTwo from '../../../img/how-to-img/mailbox.png';
import TravellerStepThree from '../../../img/how-to-img/bill-receipt.png';
import TravellerStepFour from '../../../img/how-to-img/product-delivery-tracking.png';
import TravellerStepFive from '../../../img/how-to-img/delivery-truck.png';
import TravellerStepSix from '../../../img/how-to-img/piggy-saving.png';

export default function HowToTraveller(){
  return(
    <div className="step-list">
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepOne}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 1: List a Trip</h1>
          <p className="small-para">
            Once you have registered as a traveller you can start uploading
            any trips you will be going on. Ensure to do this as soon as
            possible as they go through a verification process. Then they
            will be uploaded to the app for users to see.
          </p>
        </div>
      </div>
      <div className="step-item icon-right">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepTwo}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 2: Wait for requests</h1>
          <p className="small-para">
            You may receive requests from multiple customers wanting to
            deliver all sorts of different things. Now you get to pick and
            choose what sites you best.
          </p>
        </div>
      </div>
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepThree}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 3: Send Offer</h1>
          <p className="small-para">
            Once you have decided you would like to fulfil a delivery that
            has been sent to your trip you can send them an offer.
          </p>
        </div>
      </div>
      <div className="step-item icon-right">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepFour}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 4: Pick up item</h1>
          <p className="small-para">
            Now you will need to confirm a pick up pace for the item with
            the customer and ensure you scan the QR code when you
            receive the item. Always check it is the item you agreed on.
          </p>
        </div>
      </div>
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepFive}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 5: Deliver item</h1>
          <p className="small-para">
            Ensure to put that item in your bag and take it to the agreed
            upon destination. Change the status of the item to delivered and
            your job is done.
          </p>
        </div>
      </div>
      <div className="step-item icon-right">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepSix}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 6: Get paid </h1>
          <p className="small-para">
            Once the delivery has been confirmed by the customer you
            receive your part of the price. Don't forget to rate the Customer
            to help other Travellers decide on the items they want to take
            on their next trip.
          </p>
        </div>
      </div>
    </div>
  );
}
