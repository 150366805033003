import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { firestore, Timestamp, FieldValue, functions } from "../../Firebase";
import { Message, displayMessage } from "../ModalComponents/Spinner";
import { Link } from "react-router-dom";
import { formatFirebaseTimestamp } from "../../../utils/dateUtils";

export default function Ticket(props) {
  const endDivRef = useRef(null);

  const [ticket, setTicket] = useState();
  const [ticketLoaded, setTicketLoaded] = useState(false);
  const [messages, setMessages] = useState();
  const [reply, setReply] = useState("");

  const disableReplyBtn = (disable) => {
    const replyBtn = document.getElementById("replyButton");
    if (disable) {
      replyBtn.classList.add("disabled");
    } else {
      replyBtn.classList.remove("disabled");
    }
  };

  const scrollToBottom = () => {
    if (endDivRef) {
      endDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const getTicketNo = () => {
    if (!ticketLoaded) {
      return "Loading...";
    } else {
      if (ticket) {
        return `Ticket #${ticket.id}`;
      } else {
        return "Ticket does not exist";
      }
    }
  };

  useEffect(() => {
    const ticketId = props.ticketId;
    let listener = () => {};

    const getSupportTicketById = functions.httpsCallable(
      "getSupportTicketById"
    );

    setTicketLoaded(false);

    getSupportTicketById({ ticketId })
      .then((result) => {
        const ticket = result.data.ticket;
        setTicket(ticket);
        const messages = [];
        messages.push({
          messageBody: ticket.message,
          timestamp: ticket.createdAt,
          admin: false,
          userId: ticket.userId,
        });
        if (ticket && Array.isArray(ticket.responses)) {
          const messagesArray = ticket.responses.map((response) => ({
            messageBody: response.message,
            timestamp: response.createdAt,
            admin: response.isAdmin,
            userId: response.respondentId,
          }));
          messages.push(...messagesArray);
        }
        setMessages(messages);
        setTicketLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching ticket:", error);
        displayMessage(true, false, "Error loading ticket. Please try again.");
        setTicketLoaded(true);
      });

    return function cleanUp() {
      listener();
    };
  }, [props.ticketId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendReply = () => {
    if (!ticket || !ticket.open || reply === "") return;

    disableReplyBtn(true);

    // Call addSupportTicketResponse function
    const addSupportTicketResponse = functions.httpsCallable(
      "addSupportTicketResponse"
    );

    addSupportTicketResponse({
      ticketId: props.ticketId,
      message: reply,
    })
      .then(() => {
        setReply("");
        disableReplyBtn(true);
        const getSupportTicketById = functions.httpsCallable(
          "getSupportTicketById"
        );
        return getSupportTicketById({ ticketId: props.ticketId });
      })
      .then((result) => {
        setTicket(result.data.ticket);
        if (result.data.ticket && result.data.ticket.responses) {
          const messagesArray = result.data.responses.map((response) => ({
            messageBody: response.message,
            timestamp: response.createdAt,
            admin: response.isAdmin,
            userId: response.respondentId,
          }));
          setMessages(messagesArray);
        }
      })
      .catch((error) => {
        console.error("Error sending reply:", error);
        displayMessage(
          true,
          false,
          "You can no longer send a message in this ticket. Please create a new ticket!"
        );
        if (reply !== "") {
          disableReplyBtn(false);
        }
      });
  };

  const messageList = [];
  if (messages) {
    messages.map((data, key) => {
      messageList.push(
        <React.Fragment key={key}>
          <div className={`ticket-message ${data.admin ? "" : "from-admin"}`}>
            <div className="message">
              {data.messageBody}
              <div className="from-who">{data.admin ? "Admin" : "Me"}</div>
            </div>
            {formatFirebaseTimestamp(data.timestamp)}
          </div>
        </React.Fragment>
      );
    });
  }

  return (
    <div className="home">
      <Helmet>
        <title>
          Trippie Air - Support Ticket #{ticket ? `${ticket.id}` : ""}
        </title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <Message />
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="tickets-messages-header-text">
            <h1 className="medium-heading">Support Ticket</h1>
            <p className="medium-para">
              {getTicketNo()}
              <br />
              {ticket ? ticket.reason : ""}
            </p>
            <Link to="/support" className="standard-button invert-btn-color">
              My Tickets
            </Link>
          </div>
        </div>
      </div>
      <div className="container-middle-content ">
        <div id="messageContainer" className="ticket-messages-container">
          {messageList}
          <div ref={endDivRef}></div>
        </div>
        {ticket && ticket.open ? (
          <div className="reply-container">
            <input
              type="text"
              placeholder="Write a message"
              value={reply}
              onChange={(e) => {
                const input = e.target.value;
                disableReplyBtn(input === "");
                setReply(input);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && reply !== "") {
                  sendReply();
                }
              }}
            />
            <button
              id="replyButton"
              className="reply-button disabled"
              onClick={() => {
                if (reply !== "") {
                  sendReply();
                }
              }}
            >
              <i className="far fa-paper-plane"></i>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
