import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore, storage } from '../Firebase';

// Validation schema using yup
const schema = yup.object().shape({
  companyName: yup.string().required('Company name is required').min(3),
  address: yup.string().required('Address 2  is required').min(5),
  street: yup.string().required('Address 1 is required').min(5),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,14}$/, 'Phone number must be between 7 and 14 digits'),

  contactPersonName: yup
    .string()
    .required('Contact person name is required')
    .min(3)
    .matches(
      /^[A-Za-z\s]+$/,
      'Contact person name must only contain alphabetic characters'
    ),

  contactPersonJobTitle: yup
    .string()
    .required('Job title is required')
    .min(3)
    .matches(
      /^[A-Za-z\s]+$/,
      'Contact person name must only contain alphabetic characters'
    ),
  contactPersonPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,14}$/, 'Phone number must be between 7 and 14 digits'),
  contactPersonEmail: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  commercialLicense: yup
    .mixed()
    .required('Commercial license is required')
    .test('fileSize', 'File size should be less than 2MB', (value) => {
      return value && value[0] && value[0].size <= 10 * 1024 * 1024; // 10MB in bytes
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      return (
        value &&
        value[0] &&
        ['image/jpg', 'image/jpeg', 'image/png'].includes(value[0].type)
      );
    }),
});

export default function Register() {
  const [linkData, setLinkData] = useState();
  const [isFormSubmit, setFormSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: '',
      street: '',
      address: '',
      phoneNumber: '',
      contactPersonName: '',
      contactPersonJobTitle: '',
      contactPersonPhoneNumber: '',
      contactPersonEmail: '',
      commercialLicense: null,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docSnap = await firestore
          .collection('companies')
          .doc('links')
          .get();
        if (docSnap.exists) setLinkData(docSnap.data());
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setFormSubmit(true);
    try {
      // Check for duplicates
      const companyNameSnapshot = await firestore
        .collection('companies')
        .where('companyName', '==', data.companyName)
        .get();

      const phoneNumberSnapshot = await firestore
        .collection('companies')
        .where('phoneNumber', '==', data.phoneNumber)
        .get();

      const emailSnapshot = await firestore
        .collection('companies')
        .where('contactPersonEmail', '==', data.contactPersonEmail)
        .get();

      const duplicateCompanyName = companyNameSnapshot.docs.find(
        (doc) => doc.data().verified !== 2
      );
      const duplicatePhoneNumber = phoneNumberSnapshot.docs.find(
        (doc) => doc.data().verified !== 2
      );
      const duplicateEmail = emailSnapshot.docs.find(
        (doc) => doc.data().verified !== 2
      );

      if (duplicateEmail) {
        setError('contactPersonEmail', {
          type: 'manual',
          message: 'Email already exists. Please use a different email.',
        });
        setFormSubmit(false);
        return;
      }

      if (duplicateCompanyName) {
        setError('companyName', {
          type: 'manual',
          message: 'Company name already exists. Please use a different name.',
        });
        setFormSubmit(false);
        return;
      }

      if (duplicatePhoneNumber) {
        setError('phoneNumber', {
          type: 'manual',
          message:
            'Phone number already exists. Please use a different phone number.',
        });
        setFormSubmit(false);
        return;
      }

      // Upload the commercial license file to Firebase Storage
      const newCompanyRef = firestore.collection('companies').doc();
      const newCompanyId = newCompanyRef.id;

      const storageRef = storage.ref();
      const fileName = `${newCompanyId}.${data.commercialLicense[0].name
        .split('.')
        .pop()}`;
      const fileRef = storageRef.child(`commercialLicenses/${fileName}`);
      await fileRef.put(data.commercialLicense[0]);
      const fileUrl = await fileRef.getDownloadURL();

      // Save form data to Firestore

      await newCompanyRef.set({
        companyName: data.companyName,
        street: data.street,
        address: data.address,
        phoneNumber: data.phoneNumber,
        contactPersonName: data.contactPersonPhoneNumber,
        contactPersonJobTitle: data.contactPersonJobTitle,
        contactPersonPhoneNumber: data.contactPersonPhoneNumber,
        contactPersonEmail: data.contactPersonEmail,
        commercialLicenseUrl: fileUrl,
        verified: 0,
        isBanned: false,
        isEmailSend: false,
        lastUpdated: new Date().toISOString(),
      });

      reset(); // Clear the form
      alert('Company Profile submitted successfully!');
      window.location.reload();
    } catch (error) {
      setFormSubmit(false);
      console.error('Error submitting form: ', error);
      alert('Error submitting form. Please try again.');
    }
  };

  const link = linkData ? linkData.terms : null;

  return (
    <div className="home">
      <Helmet>
        <title>Trippie Air - Register Company</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="about-header-text">
            <h1 className="medium-heading">
              Logistic Company Partner Registration
            </h1>
            <p className="medium-para">
              Please fill out the form below with your company details
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-10 m-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-4 form-group">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    {...register('companyName')}
                  />
                  <p className="text-danger">{errors.companyName?.message}</p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="address">Address 1</label>

                  <textarea
                    className="form-control"
                    id="street"
                    {...register('street')}
                  />
                  <p className="text-danger">{errors.street?.message}</p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="address">Address 2</label>
                  <textarea
                    className="form-control"
                    id="address"
                    {...register('address')}
                  />
                  <p className="text-danger">{errors.address?.message}</p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    {...register('phoneNumber')}
                  />
                  <p className="text-danger">{errors.phoneNumber?.message}</p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="contactPersonName">Contact Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactPersonName"
                    {...register('contactPersonName')}
                  />
                  <p className="text-danger">
                    {errors.contactPersonName?.message}
                  </p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="contactPersonJobTitle">
                    Contact Person Job Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactPersonJobTitle"
                    {...register('contactPersonJobTitle')}
                  />
                  <p className="text-danger">
                    {errors.contactPersonJobTitle?.message}
                  </p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="contactPersonPhoneNumber">
                    Contact Person Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="contactPersonPhoneNumber"
                    {...register('contactPersonPhoneNumber')}
                  />
                  <p className="text-danger">
                    {errors.contactPersonPhoneNumber?.message}
                  </p>
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="contactPersonEmail">
                    Contact Person Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contactPersonEmail"
                    {...register('contactPersonEmail')}
                  />
                  <p className="text-danger">
                    {errors.contactPersonEmail?.message}
                  </p>
                </div>
                <div className="col-md-8 form-group">
                  <label htmlFor="commercialLicense">
                    Upload Commercial License (.jpeg, .pdf, .png, .jpg, .tiff,
                    .gif)
                  </label>
                  <Controller
                    name="commercialLicense"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="file"
                        className="form-control-file"
                        id="commercialLicense"
                        accept=".jpg,.tiff, .gif, .pdf,.gpeg, .jpeg, .png"
                        onChange={(e) => field.onChange(e.target.files)}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errors.commercialLicense?.message}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 m-auto text-center">
                  <button
                    type="submit"
                    // aria-disabled={isFormSubmit}
                    disabled={!isValid || isSubmitting || isFormSubmit}
                    className="btn btn-block btn-primary bg-primary-custom"
                  >
                    {isFormSubmit ? 'Submitting....' : 'Submit'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
