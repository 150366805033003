export const formatFirebaseTimestamp = (
  timestamp,
  locale = "en-NZ",
  options = {}
) => {
  if (!timestamp || !timestamp._seconds) {
    return "Invalid date";
  }

  try {
    const date = new Date(timestamp._seconds * 1000);
    return date.toLocaleString(locale, options);
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "Invalid date";
  }
};

export const formatLatestTimestamp = (
  items,
  timestampField,
  locale = "en-NZ",
  options = {},
  fallbackText = "No date available"
) => {
  if (!items || !items.length) {
    return fallbackText;
  }

  const latestItem = items.reduce((latest, current) => {
    if (!current[timestampField] || !current[timestampField]._seconds) {
      return latest;
    }

    if (
      !latest ||
      current[timestampField]._seconds > latest[timestampField]._seconds
    ) {
      return current;
    }

    return latest;
  }, null);

  if (latestItem && latestItem[timestampField]) {
    return formatFirebaseTimestamp(latestItem[timestampField], locale, options);
  }

  return fallbackText;
};
