import React, { Component } from "react";
import { auth } from "../../Firebase";

import Login from "./Login";
import Ticket from "./Ticket";

export default class TicketView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      let currentUser = null;
      if (user && user.emailVerified) {
        currentUser = user;
      }
      this.setState({ user: currentUser });
    });
    const ticketId = window.location.pathname.split("/").pop();
    if (ticketId) {
      this.setState({ ticketId });
    }
  }

  render() {
    const user = this.state.user;
    return user ? (
      <Ticket user={user} ticketId={this.state.ticketId} />
    ) : (
      <Login />
    );
  }
}
