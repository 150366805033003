import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "../App.min.css";

import About from "../components/mainComponents/About";
import Faq from "../components/mainComponents/FAQ";
import Home from "../components/mainComponents/Home.js";
import Legal from "../components/mainComponents/Legal";
import Register from "../components/mainComponents/Register";
import Navigation from "../components/navigation/Navigation.js";
import TicketListView from "./mainComponents/SupportComponents/SupportDashboard.js";
import TicketCreateView from "./mainComponents/SupportComponents/TicketCreateView.js";
import TicketView from "./mainComponents/SupportComponents/TicketView.js";

export default function App() {
  return (
    <Router>
      <Navigation />
      <>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/register" element={<Register />} />

          <Route exact path="/support" element={<TicketListView />} />
          <Route path="/support/create-ticket" element={<TicketCreateView />} />
          <Route path="/support/ticket/:ticketId" element={<TicketView />} />
        </Routes>
        <div className="footer-container">
          <div className="container-middle-content footer">
            Copyright © 2020 Trippie | Powered by&nbsp;{" "}
            <a
              className="developer-link"
              rel="noreferrer"
              href="http://jamesdwhite.me/"
              target="_blank"
            >
              {" "}
              James White
            </a>
          </div>
        </div>
      </>
    </Router>
  );
}
