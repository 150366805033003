import React, { Component } from "react";
import Faq from "react-faq-component";
import { firestore } from "../../Firebase";

const styles = {
    titleTextColor: "black",
    rowTitleColor: "#01a2cc",
    rowTitleTextSize: '20px',
    rowContentColor: "#393939",
    rowContentTextSize: '18px',
    rowContentPaddingBottom: '10px'
};

const config = {
    animate: true
};

const faqTitles = {
    general: "General FAQ",
    sender: "Sender FAQ",
    traveller: "Traveller FAQ"
};

export default class FaqList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.faqType !== prevProps.faqType) {
      this.getFaqData();
    }
  }

  getFaqData() {
    const faqType = this.props.faqType;
    const faqQuery = firestore.collection("faqs")
      .where("type", "==", faqType);

      faqQuery.get().then(querySnap => {

        const faqData = [];

          querySnap.forEach(docSnap => {

            const docData = docSnap.data();

            faqData.push({
                title: docData.title,
                content: docData.content
            });
          });

          this.setState({
            faqData: {
              title: faqTitles[faqType],
              rows: []
            }
          }, () => {

            this.setState({
              faqData: {
                title: faqTitles[faqType],
                rows: faqData
              }
            });
          });
      });
  }

  componentDidMount() {
    this.getFaqData();
  }

  render() {
    const faqData = this.state.faqData;

    return (
      <div className="container">
        {faqData ? <Faq data={faqData} styles={styles} config={config} /> : <></>}
      </div>
    );
  }
}
